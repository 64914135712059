import React from 'react'
import type { MessageDescriptor } from 'react-intl'
import { FormattedMessage } from 'gatsby-plugin-intl'

type Props = MessageDescriptor & {
  values: any
}

export const FormattedMessageSanitized = (props: Props) => {
  return (
    <FormattedMessage
      {...props}
      values={{
        b: (...chunks) => <b>{chunks}</b>,
        p: (...chunks) => <p>{chunks}</p>,
        h1: (...chunks) => <h1>{chunks}</h1>,
        h2: (...chunks) => <h2>{chunks}</h2>,
        h3: (...chunks) => <h3>{chunks}</h3>,
        h4: (...chunks) => <h4>{chunks}</h4>,
        h5: (...chunks) => <h5>{chunks}</h5>,
        sup: (...chunks) => <sup>{chunks}</sup>,
        ul: (...chunks) => <ul>{chunks}</ul>,
        li: (...chunks) => <li>{chunks}</li>,
        ol: (...chunks) => <ol>{chunks}</ol>,
        strong: (...chunks) => <strong>{chunks}</strong>,
        small: (...chunks) => <small>{chunks}</small>,
        br: <br/>,
        ...props.values
      }}
    />
  )
}
